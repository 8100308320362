import React from 'react';
import styles from '../styles/AboutMe.module.css';

const AboutMe = () => {
    return (
        <div className={styles.aboutMeContainer}>
            <div className={styles.content}>
                <h1 class>about</h1>
                <p>
                    I'm 25, living in Bushwick, Brooklyn.

                <p>I work at EY on the Digital Asset Research Team, as an Engineer and Consultant.</p> 
                </p>
                <p>
                    I have two personas - one TradFi, one degen. TradFi is intriguing to me because of its power. 
                    DeFi and web3 is exciting because of its creativity. 
                </p>
                <p> 
                    I enjoy designing and engineering things, with a focus on craftsmanship. 
                </p>
            </div>
        </div>
    )
};

export default AboutMe;