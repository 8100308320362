import React, { useState, useEffect } from 'react';
import styles from './styles/App.module.css'
import Profile from './Profile';
import AboutMe from './components/AboutMe';
import Art from './components/Art';
import Writing from './components/Writing';
import NFTGallery from './components/NFTGallery';




const App = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  useEffect(() => {
    localStorage.setItem('currentTab', activeTab);
  }, [activeTab]);


  const artData = [
    { src: '/marshaspark.jpeg', title: 'NYC', description: 'Marsha P. Johnson State Park' },
    { src: '/hunterspoint.jpeg', title: 'NYC', description: 'Hunters Point' },
    { src: '/bangkok-traffic.jpg', title: 'Bangkok', description: 'Asok, Bangkok' },
    { src: '/buddhas.jpg', title: 'Bangkok', description: 'Wat Po, Bangkok' },
    { src: '/building-bangkok.jpg', title: 'Bangkok', description: 'Phloen Chit, Bangkok' },
    { src: '/bangkok-highrise.jpg', title: 'Bangkok', description: 'Khlong Tan, Bangkok' },
    { src: '/giant-swing.jpg', title: 'Bangkok', description: 'Giant Swing, Wat Suthat' },
    { src: '/canal-bangkok.jpg', title: 'Bangkok', description: 'Wat Arun, Bangkok' },
    { src: '/boat-ride.jpg', title: 'Bangkok', description: 'Wat Arun, Bangkok' },
    { src: '/banana-streetfood-bangkok.jpg', title: 'Bangkok', description: 'Street Food, Phraborom Maharatchawang' },
    { src: '/thai-streetfood4.jpg', title: 'Bangkok', description: 'Street Food, Phraborom Maharatchawang' }, 
    { src: '/big-buddha.jpg', title: 'Bangkok', description: 'Big Buddha from Khlong Bangkok Yai' },
    { src: '/thai-temple.jpg', title: 'Bangkok', description: 'Wat Po, Bangkok' },
    { src: '/thai-street-food.jpg', title: 'Bangkok', description: 'Street Food, Wang Burapha Phirom' },
    { src: '/night-ride.jpg', title: 'Bangkok', description: 'Thanon Phet Buri, Bangkok' },
    { src: '/thai-boat-ride.jpg', title: 'Bangkok', description: 'Phraborom Maharatchawang, Bangkok' },
    { src: '/thai-boxing-stadium.jpg', title: 'Bangkok', description: 'Rajadamnern Stadium, Bangkok' },
    { src: '/bangkok.jpg', title: 'Bangkok', description: 'Pathum Wan, Bangkok' },
    { src: '/me-bangkok.jpg', title: 'Bangkok', description: 'Khlong Tan Nuea, Bangkok' },
    { src: '/untitled.png', title: 'new york', description: '' },
    { src: '/roosevelt.png', title: 'new york', description: '' },
    { src: '/halsey.png', title: 'new york', description: '' },
    { src: '/basquiat.png', title: 'Basquiat', description: '' },
    { src: '/graffiti.png', title: 'Chinatown', description: 'Street Art, New York' },
    { src: '/taiwan-us.jpg', title: 'Chinatown', description: 'Chinatown, New York' },
    { src: '/brooklyn.jpg', title: 'rainbow', description: 'Rainbow over Brooklyn' },
    { src: '/bc.jpg', title: 'Bushwick Collective', description: 'Bushwick Collective' },
    { src: '/bigge.jpg', title: 'Biggie', description: 'Biggie and Jean-Michel Basquiat Mural' },
    { src: '/biggie.jpg', title: 'mural', description: 'Bushwick Collective' },
    { src: '/centralpark.jpg', title: 'central park', description: 'Central Park' },
    { src: '/mona-lisa2.jpg', title: 'Mona Lisa', description: 'The Mona Lisa, by Da Vinci' },
    { src: '/louvre2.jpg', title: 'Louvre', description: 'Louvre' },
    { src: '/mona-lisa1.jpg', title: 'Mona Lisa', description: 'Mona Lisa Room' },
    { src: '/lourve.jpg', title: 'Me', description: 'Me' },
    { src: '/inside.jpg', title: 'Louvre', description: 'Inside the Louvre' },
    { src: '/paris2.jpg', title: 'Paris', description: 'Paris, France' },
    { src: '/paris.jpg', title: 'Olympics Paris', description: 'Olympics, Paris' },
    { src: '/effiel-tower.jpg', title: 'Eiffel Tower', description: 'Eiffel Tower' },
    { src: '/grand-place.jpg', title: 'Grand Place', description: 'Grand Place, Brussels' },
    { src: '/waffle.jpg', title: 'Waffle', description: 'Belgium Waffle' },
    { src: '/belgium.jpg', title: 'Brussles', description: 'Brussles, Belgium' },
    { src: '/belgium-5.jpg', title: 'Brussles', description: 'Brussels, Belgium' },
    { src: '/hong-kong3.jpg', title: 'Buddah', description: 'Tian Tan Buddah, Ngong Ping' },
    { src: '/hong-kong2.jpg', title: 'Hong Kong', description: 'Lantau Island, Hong Kong' },
    { src: '/hong-kong.jpg', title: 'Hong Kong', description: 'Tsim Sha Tsui, facing Hong Kong Island' },
    { src: '/elephant-trail.jpg', title: 'Taipei', description: 'Elephant Trail, Taipei' },
    { src: '/taipei.jpg', title: 'Taipei', description: 'Songshan District, Taipei' },
    { src: '/zs.jpg', title: 'Taipei', description: 'Zhongshan District, Taipei' },
    { src: '/wulai2.jpg', title: 'Taipei', description: 'Wulai, New Taipei City' },
    { src: '/taipei2.jpg', title: 'Taipei', description: 'Songshan District, Taipei' },
    { src: '/taipei101.jpg', title: 'Taipei', description: 'Taipei 101, from Xiangshan' },
    { src: '/baseethdenver.png', title: 'ETHDenver', description: 'ETHDenver, Base Booth' },
    { src: '/ethdenver.png', title: 'ETHDenver', description: 'ETHDenver 2024' },
    { src: '/boredapecaptain.jpg', title: 'Web3 People', description: 'Designed at ETHIstanbul 2023' },
    { src: '/turkey.jpg', title: 'Istanbul', description: 'Beyoglu, Istanbul'},
    { src: '/pubkey.jpg', title: 'pubkey', description: 'Pubkey Key, West Village' }, 
    { src: '/goldengate.jpg', title: 'Golden Gate', description: '' },
    { src: '/gate.jpg', title: 'Golden Gate', description: 'Golden Gate' },
    { src: '/alcatraz.jpg', title: 'Golden Gate', description: 'Golden Gate from Alcatraz' },
    { src: '/unnamed.jpg', title: 'Playing for the World', description: 'From Coit Tower' },
    { src: '/ethdam.jpg', title: 'ethdam', description: 'ETHAmsterdam 2023.' },
    { src: '/amsterdamcanal.jpg', title: 'canal', description: 'The canals in Amsterdam' },
    { src: '/oxfordcircus.jpg', title: 'Playing for the World', description: 'Oxford Circus, London' },
    { src: '/london.jpg', title: 'london', description: 'London South Bank' },
    { src: '/therockies.jpg', title: 'therockies', description: 'The Rockies, Denver' },
    { src: '/wyoming.jpg', title: 'wyoming', description: 'Wyoming' },

  ]
  const blogEntries = [
    // {
    //   title: 'DeSo (The Decentralized Society)',
    //   date: 'January 2024',
    //   description: 
    //     `The endgame for web3 is the decentralized society. Internet users working whenever they want,
    //     whenever they want. Engaging in creative work with friends and communities. Gaining reputation and clout on 
    //     merit and history of participation. Having multiple identities for plug-and-play with anonymity at their descretion.
    //     Where are these applications?`,
    //   link: 'deso.html'
    // },
    { 
      title: 'DevCon 7',
      date: 'November 2024', 
      description: 
        `My record of DevCon 7`, 
      link: 'devcon.html', 
    },
    { 
      title: 'Love of Product',
      date: 'October 2024', 
      description: 
        `Art vs. Commerce`, 
      link: 'loveofproduct.html', 
    },
    // { 
    //   title: 'Art',
    //   date: 'August 2024', 
    //   description: 
    //     `What does art mean.`, 
    //   link: 'art.html', 
    // },

    { 
      title: 'FTX and Armanino',
      date: 'December 2023', 
      description: 
        `We were like the hedgefunds from The Big Short. Every major CeFi exhange wanted a proof-of-reserves.
          Binance, KuCoin, OKX, Crypto.com, Kraken, Gate.io. We hit the jackpot, but things took a turn as PR pressure
          weighed on the partners at higher levels of the firm.`, 
      link: 'armanino.html', 
    },
  //  { 
    //   title: 'Proof of Reserves',
    //   date: 'December 2023', 
    //   description: 
    //     `Explanation on  proof of reserves - how it works, and the data structure behind it.`, 
    //   link: 'proof-of-reserves.html', 
    // },
    // { 
    //   title: 'FTX and Armanino',
    //   date: 'December 2023', 
    //   description: 
    //     `We were like the hedgefunds from The Big Short. Every major CeFi exhange wanted a proof-of-reserves.
    //       Binance, KuCoin, OKX, Crypto.com, Kraken, Gate.io. We hit the jackpot, but things took a turn as PR pressure
    //       weighed on the partners at higher levels of the firm.`, 
    //   link: 'armanino.html', 
    // },
    // { 
    //   title: 'Why I got into Web3',
    //    date: 'December 2023', 
    //    description: 
    //    `Kurt Godel and his 1931 Incompleteness Theorem is why. 
    //     The theorem disproved "Hilbert's Program" - a mathematical quest to render the universe as a logical machine.
    //     Godel's Theorem proved that in principle, Hilbert's Machine would always be "Incomplete" - That the system always requires
    //     nuiance, and creativity. The corollary to this is Blockchain and AI. 100 years later, we face the same dilemma. I realized that blockchain technology 
    //     is Godel's thereom to superintelligent AI of Hilbert. History has a way of rhyming`, 
    //    link: '/intro-to-web3.html', 
    // }

  ];

  // const tokenData = [
  //   { src: 'https://raw.seadn.io/files/3d0c6698c54fae51591f884eef0efe22.gif', alt: 'ENS Happy Hour', title: 'ENS ETH NY 2023'}, 
  //   { src: 'https://raw.seadn.io/files/bb7014c92dd89d0539caf65452f24ab3.jpg', alt: 'ENS Grants Voter Card ', title: 'ENS Voter Card'},
  //   { src: 'https://raw.seadn.io/files/8f9478cdf989be08511c8ad9bd12fac5.svg', alt: 'Godel', title: 'ENS Name'}, 
  //   { src: 'https://raw.seadn.io/files/254d6af8a4fe472e5fa81e00ba017f0c.svg', alt: 'persona', title: 'ENS Name on .Art'}, 
  //   { src: '/ethnyc.jpg', alt: 'ETH NYC', title: 'ETH NYC POAP'}, 
  //   { src: '/ethistanbul.jpg', alt: 'ETH Istanbul', title: 'ETH Istanbul POAP'}, 
  //   { src: '/ensanniversary.jpg', alt: 'ENS Anniversary', title: 'ENS Anniversary POAP'},
  //   { src: 'https://dl.openseauserdata.com/cache/originImage/files/351871dea08d4069c4fb15c888f26fac.png', alt: 'Femi', title: 'OG Pixel Portaits'},
  //   { src: 'https://raw.seadn.io/files/133ef7257044b7f365659c3f9284a3f4.png', alt: 'web3marketing', title: 'Web3 Marketing'},
  //   { src: 'https://raw.seadn.io/files/91db153dba78d6c63d0f74b00b4c82f0.png', alt: 'coinbase earnings', title: "COIN Earnings | Q4'23"},
  //   { src: 'https://raw.seadn.io/files/6efee9ecab954f3cf43515f9dcb34c6a.svg', alt: 'read write own', title: "Read Write Own #1003"},
  //   { src: 'https://raw.seadn.io/files/9866e139c52e7f68775a5090c7b98031.jpg', alt: 'Mirror.xyz Article', title: 'Keep doing cool shit online'},
  //   { src: 'https://raw.seadn.io/files/7db76c4f249785116b525d1144647b62.jpg', alt: 'Thus Spoke Zarathustra', title: 'Thus Spoke Zarathustra, Friedrich Nietzsche'},
  //   { src: 'https://dl.openseauserdata.com/cache/originImage/files/98875395dd93ea1daddcc78ad5dbb407.png', alt: 'armanino', title: 'Armanino Swag Badge'},
  //   { src: 'https://raw.seadn.io/files/02822f32f1610133dba3173b2c6eb533.svg', alt: 'typecheck', title: 'TypeCheck 2'}
  // ];

  return (
    <div className={styles.appContainer}>
      <div className={styles.fixedContainer}>
        <div className={styles.tabsContainer}>
          <div className={styles.tabsList}>
            <p
              className={activeTab === 'tab1' ? styles.activeTab : styles.tab}
              onClick={() => setActiveTab('tab1')}
              
            >
              me
            </p>
            <p
              className={activeTab === 'tab2' ? styles.activeTab : styles.tab}
              onClick={() => setActiveTab('tab2')}
            >
              entries
            </p>
            <p
              className={activeTab === 'tab3' ? styles.activeTab : styles.tab}
              onClick={() => setActiveTab('tab3')}
            >
              gallery
            </p>
            <p 
              className={activeTab === 'tab4' ? styles.activeTab : styles.tab}
              onClick={() => setActiveTab('tab4')}
            >
              tokens
            </p>
          </div>
        </div>
        <div className={styles.profileContainer}>
          <Profile
            imageUrl="/pfp.jpg"
            name="Daniel Adebimpe"
            twitter="https://twitter.com/fem1bimpe"
            github="https://github.com/danieloadebimpe"
            discord="https://discordapp.com/users/745292081206919268"
            telegram="https://t.me/doa_man"
            linkedin="https://www.linkedin.com/in/daniel-o-adebimpe-0aa91a139/"
          />
        </div>
      </div>
      <div className={styles.contentContainer}>
        {activeTab === 'tab1' && <AboutMe />}
        {activeTab === 'tab2' && <Writing entries={blogEntries} />}
        {activeTab === 'tab3' && <Art artPieces={artData} />}
        {activeTab === 'tab4' && (<NFTGallery />)}
      </div>
    </div>
  );
};



export default App;
